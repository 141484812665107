@font-face {
  font-family: 'Rubik';
  src: local('./assets/font/Rubik.ttf') format(truetype);
}

body {
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinny-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

/*--------------------------------------------------
-------------------- rotate words ------------------
---------------------------------------------------*/

.spinny-words {
  display: inline-block;
  min-width: 350px;
  text-align: left;
}
.spinny-words span {
  position: absolute;
  font-weight: bold;
  top: -0px;
  opacity: 0;
  animation: rotateWord 18s linear infinite 0s;
}
.spinny-words span:nth-child(2) {
  animation-delay: 3s;
}
.spinny-words span:nth-child(3) {
  animation-delay: 6s;
}
.spinny-words span:nth-child(4) {
  animation-delay: 9s;
}
.spinny-words span:nth-child(5) {
  animation-delay: 12s;
}
.spinny-words span:nth-child(6) {
  animation-delay: 15s;
}

@keyframes rotateWord {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 0;
    transform: translateY(-30px);
  }
  5% {
    opacity: 1;
    transform: translateY(0px);
  }
  17% {
    opacity: 1;
    transform: translateY(0px);
  }
  20% {
    opacity: 0;
    transform: translateY(30px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

::selection {
  background: #e0e0ff;
}

.wrapper {
  position: relative;
}

.wrapper:before {
  background-image: url(./assets/reviews/1.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  height: 270px;
  left: 10px;
  position: absolute;
  top: 6%;
  width: 170px;
  zindex: 1;
}

.wrapper:after {
  background-image: url(./assets/reviews/2.svg);
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 20%;
  content: '';
  height: 120px;
  position: absolute;
  right: 0;
  width: 120px;
  z-index: 1;
}

.stickyHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.wrapper-two {
  background: url(./assets/section_bg2.svg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5rem 0;
}

.wrapper-three {
  background: url(./assets/section_bg3.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5rem 0;
}

.about-me-container {
  position: relative;
}

.about-me-container:after {
  background-image: url(./assets/about-me/container_vector.svg);
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: '';
  height: 150px;
  position: absolute;
  right: 0;
  width: 150px;
}

.pattern-overlay {
  background-image: url(./assets/about-me/image_overlay.svg);
  background-repeat: no-repeat;
  background-size: contain;
  height: 120%;
  left: 50%;
  position: absolute;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 120%;
  z-index: 1;
}
